<template>
  <div class="form-container">
    <form @submit.prevent="submitNewPass" class="form-group">
        <b-input-group>
          <b-form-input
            id="pass-input"
            :type="newPasswordFieldType"
            v-model="user.newPassword"
            :class="{ 'is-invalid': $v.user.newPassword.$error }"
            placeholder="Digite sua senha"
            class="inputText"
            @focus="onFocusChange()"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text>
              <b-icon :icon="changeIcon('newPassword')"  @click="switchVisibility('newPassword')" />
            </b-input-group-text>
          </b-input-group-append>
          <div v-show="$v.user.newPassword.$error"
            class="invalid-feedback">
            <span v-show="!$v.user.newPassword.required">Senha é obrigatória.<br /></span>
            <span v-show="!$v.user.newPassword.minLength">Senha deve ter no mínimo 8 caracteres.<br /></span>
            <span v-show="!passwordUppercase"> Senha deve ter uma letra maiúscula.<br /></span>
            <span v-show="!passwordLowercase">Pelo menos um caractere minúsculo.<br /></span>
            <span v-show="!passwordSpecial">Pelo menos um caractere especial (!@#()-[{}]:;',?/*~$^+=.`|%).
            <br /></span>
            <span v-show="user.newPassword && !passwordNumber">Senha deve contar ao menos um número.
            <br /></span>
          </div>
        </b-input-group>
        <b-input-group style="margin-top: 15px;">
          <b-form-input
            v-model="user.confirmNewPassword"
            id="confirmNewPassword"
            :type="confirmNewPasswordFieldType"
            name="confirmNewPassword"
            class="inputText"
            placeholder="Confirme sua senha"
            :class="{
              'is-invalid': $v.user.confirmNewPassword.$error,
            }"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text>
              <b-icon
                :icon="changeIcon('confirmNewPassword')"
                @click="switchVisibility('confirmNewPassword')"
              />
            </b-input-group-text>
          </b-input-group-append>
          <div
            v-if="$v.user.confirmNewPassword.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.user.confirmNewPassword.required"
              >Confirmação de senha é obrigatória.</span
            >
            <span v-else-if="!$v.user.confirmNewPassword.sameAsPassword"
              >Senhas devem ser iguais</span
            >
          </div>
        </b-input-group>
      <div class="submit-button">
        <b-button class="new-monitor-button" type="submit">Alterar</b-button>
      </div>
    </form>
    <p v-show="showTextVersion" class="text-version">
      Ver.: {{version()}}
    </p>
  </div>
</template>

  <script>
  import { userService } from '../services/user_service.js';
  import { required, minLength, sameAs } from 'vuelidate/lib/validators';

  export default {
    props: {
      showTextVersion: { type: Boolean, required: true },
      userLogin: { type: String, required: true },
      redirectLoginPage: { type: Boolean, required: true }
    },
    data () {
      return {
        user: {
          login: this.userLogin,
          newPassword: '',
          confirmNewPassword: ''
        },
        newPasswordFieldType: 'password',
        confirmNewPasswordFieldType: 'password',
        passwordUppercase: false,
        passwordLowercase: false,
        passwordNumber: false,
        passwordSpecial: false
      };
    },
    validations: {
      user: {
        newPassword: {
          required,
          valid: function (value) {
            this.passwordUppercase = /[A-Z]/.test(value);
            this.passwordLowercase = /[a-z]/.test(value);
            this.passwordNumber = /[0-9]/.test(value);
            this.passwordSpecial = /[#?!+@$;:%^&*-]/.test(value);
            return (
              this.passwordUppercase &&
              this.passwordLowercase &&
              this.passwordNumber &&
              this.passwordSpecial
            );
          },
          minLength: minLength(8)
        },
        confirmNewPassword: { required, sameAsPassword: sameAs('newPassword') }
      }
    },
    methods: {
      changeIcon (passwordField) {
        return this[`${passwordField}FieldType`] === 'password' ? 'eye' : 'eye-slash';
      },
      version () {
        return process.env.VUE_APP_VERSION;
      },
      async submitNewPass (e) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const submitResult = await userService.submitNewPass(this.user);
        if (submitResult) {
          this.$toast.open('Senha alterada com sucesso!');
          if (this.redirectLoginPage) {
            setTimeout(() => {
              this.$router.push({ path: 'login' });
            }, 3500);
          }
        }
      },
      switchVisibility (passwordField) {
        this[`${passwordField}FieldType`] =
          this[`${passwordField}FieldType`] === 'password' ? 'text' : 'password';
      },
      onFocusChange () {
        this.$v.$touch();
        if (this.$v.$invalid) {
          const value = this.user.newPassword;
          this.passwordUppercase = /[A-Z]/.test(value);
          this.passwordLowercase = /[a-z]/.test(value);
          this.passwordNumber = /[0-9]/.test(value);
          this.passwordSpecial = /[#?!+@$;:%^&*-]/.test(value);
          return (
            this.passwordUppercase &&
            this.passwordLowercase &&
            this.passwordNumber &&
            this.passwordSpecial
          );
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
.form-container {
  padding: 0 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  form {
    margin-top: 20px;
    justify-content: space-evenly;
    /deep/label {
      margin-bottom: 0.1rem !important;
    }
    input:focus {
      box-shadow: none;
    }
    .submit-button {
      display: flex;
      justify-content: flex-end;
      button[type="submit"] {
        background: #207BDD;
        border-radius: 4px;
        border-color: #207BDD;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: center;
        width: 90px;
        margin-top: 10px;
        text-transform: none;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
          0 0 0 0px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        &:hover {
          background-color: #b3c5d4;
          transition: background-color 0.2s linear;
        }
      }
    }
  }
  .text-version {
    margin: 0;
    bottom: 0;
    right: 80px;
    margin-bottom: 10px;
    font-size: 10px;
    color: #bdbdbd;
    text-align: center;
    position: absolute;
  }
}

.inputText {
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px 16px 12px;
        gap: 12px;
        width: 368px;
        font-weight: 400;
        font-size: 14px;

        height: 53px;
        left: 0%;
        right: 0%;
        top: calc(50% - 53px/2 + 6px);

        /* branco */
        background: #FFFFFF;

        /* cinza bordas */
        border: 1px solid #E6ECF2;
        border-radius: 4px;
    }
.invalid-feedback {
  margin-top: 0.1rem !important;
}
.form-group {
  margin-bottom: 0.5rem !important;
  justify-content: space-evenly;
}
</style>
